
.event-photo img, .event-satellite img, .new-event img {
  width: 100%;
  height: 320px;
  object-fit: cover;
}

.event-satellite img {
  touch-action: manipulation;
}

